"use client";

import { useLocalStorage, useMediaQuery } from "usehooks-ts";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import Image from "next/image";
import { useTranslations } from "~/hooks/useTranslations";
import { isDtmShop } from "~/lib/client/isDtmShop";
import { Month } from "~/lib/definitions";
import { Button } from "../ui/button";
import dayjs from "dayjs";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "../ui/drawer";

const StartDate = dayjs()
  .tz("CET")
  .year(2025)
  .month(Month.April)
  .date(4)
  .startOf("day");

const EndDate = dayjs()
  .tz("CET")
  .year(2025)
  .month(Month.April)
  .date(27)
  .endOf("day");

const ImageSrc = "/dtm_oschersleben_track_safari.jpg";
const ImageAlt = "DTM Oschersleben Track Safari";

const PromoName = "show-dtm-oschersleben-track-safari-84e9e58c";

export function DtmPromotionSpecial() {
  const [show, setShow] = useLocalStorage(PromoName, true);

  const t = useTranslations();

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const isBetweenStartAndEnd =
    dayjs().tz("CET").isAfter(StartDate) && dayjs().tz("CET").isBefore(EndDate);

  const title = t.dtm.promo_special.title;
  const description = t.dtm.promo_special.description;

  const infos = [
    t.dtm.promo_special.info_1,
    t.dtm.promo_special.info_2,
    t.dtm.promo_special.info_3,
  ];

  const continueText = t.dtm.promo_special.continue;

  return (
    isDtmShop() &&
    isBetweenStartAndEnd &&
    (isDesktop ? (
      <AlertDialog open={show} onOpenChange={setShow}>
        <AlertDialogContent className="max-w-[700px]">
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>

            <AlertDialogDescription>{description}</AlertDialogDescription>
          </AlertDialogHeader>

          <div className="flex gap-4">
            <Image
              height={200}
              width={200}
              src={ImageSrc}
              alt={ImageAlt}
              className="rounded"
            />

            <div className="flex grow flex-col">
              <ul className="ml-4 grow list-['-_'] md:text-sm [&_a]:text-link">
                {infos.map((info) => (
                  <li
                    key={crypto.randomUUID()}
                    dangerouslySetInnerHTML={{ __html: info }}
                  />
                ))}
              </ul>

              <div className="flex justify-end">
                <Button onClick={() => setShow(false)}>{continueText}</Button>
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    ) : (
      <Drawer open={show} onOpenChange={setShow}>
        <DrawerContent>
          <DrawerHeader className="text-left">
            <DrawerTitle>{title}</DrawerTitle>

            <DrawerDescription>{description}</DrawerDescription>
          </DrawerHeader>

          <div className="flex flex-col gap-4 p-4">
            <Image
              height={200}
              width={200}
              src={ImageSrc}
              alt={ImageAlt}
              className="self-center rounded"
            />

            <ul className="ml-4 list-['-_'] [&_a]:text-link">
              {infos.map((info) => (
                <li
                  key={crypto.randomUUID()}
                  dangerouslySetInnerHTML={{ __html: info }}
                />
              ))}
            </ul>

            <div className="flex justify-end pt-4">
              <Button onClick={() => setShow(false)}>{continueText}</Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    ))
  );
}
